import {RawAxiosRequestConfig} from 'axios';
import HttpService from '../httpService';
import {IAutoComplete} from './types';

export const settingsAccessoryResource = 'SettingsAccessory';
export const settingsRollerShadeResource = 'SettingsRollerShade';
export const settingsZebraShadeResource = 'SettingsZebraShade';
export const settingsDraperyResource = 'SettingsDrapery';

export const shadeConfigUrl = '/v1/ShadeConfiguration';

export const productType = [
  {id: 1, name: 'Accessory'},
  {id: 2, name: 'Extrusion'},
  {id: 3, name: 'Loop'},
  {id: 4, name: 'Cassette'},
  {id: 5, name: 'CassetteCover'},
];

export const productResource = 'SettingsProduct';

export interface IProductItem {
  id: number;
  text: string;
  url: string;
}

export interface IProduct {
  id: number;
  name: string;
  markup: number;
  labor: number;
  etc: number;
}

export default new (class ProductService extends HttpService {
  constructor() {
    super();
  }

  url = '/v1/Products';

  async getProductColors(productId: number) {
    const {data} = await this.getBase<Array<IAutoComplete>>(`${this.url}/${productId}/Colors`);

    return data;
  }

  async getProductItems(productId: number, itemTypeId: number) {
    const {data} = await this.getBase<any>(`${this.url}/${productId}/Items`, {
      params: {
        itemTypeId,
      },
    });

    return data;
  }

  async getProductItemTypesAssigned(productId: number) {
    const {data} = await this.getBase(`${this.url}/${productId}/ItemTypesAssigned`);

    return data;
  }

  async getProductItemsAvailable(productId: number, page: number, pageSize: number, searchTerm: any) {
    const {data} = await this.getBase<any>(`${this.url}/${productId}/ItemsAvailable`, {
      params: {
        page,
        pageSize,
        searchTerm,
      },
    });

    return data;
  }

  async getProductItemsAssigned(productId: number, page: number, pageSize: number, searchTerm: any) {
    const {data} = await this.getBase<any>(`${this.url}/${productId}/ItemsAssigned`, {
      params: {
        page,
        pageSize,
        searchTerm,
      },
    });

    return data;
  }

  async getProductItemConfigs(productId: number, page: number, pageSize: number, searchTerm: any, filters?: any) {
    let config: RawAxiosRequestConfig = {
      params: {
        page,
        pageSize,
        searchTerm,
      },
    };

    if (filters !== undefined) {
      config.params = {
        ...config.params,
        description: filters.description,
        itemConfigSku: filters.itemConfigSku,
      };
    }

    const {data} = await this.getBase<any>(`${this.url}/${productId}/ItemConfigs`, config);

    return data;
  }

  async getProductConfigurations() {
    const {data} = await this.getBase<any>(`${shadeConfigUrl}/Configurations`);

    return data;
  }

  async getShadeConfiguration(id: number) {
    const {data} = await this.getBase<any>(`${shadeConfigUrl}/Configuration/${id}`);

    return data;
  }

  async getAllProducts() {
    const {data} = await this.getBase<any>(`${this.url}`);

    return data;
  }
})();
